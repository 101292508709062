import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import conditional from "../../utils/conditional";
import { useAppState } from "../../contexts/AppState";
import icons from "../../resources/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingUser,
  faUsers,
  faHome,
  faMessage,
  faUserAlt,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = (props) => {
  const { path, setPath, auth, groupAdmin, role } = useAppState();
  const [isPuzzleAdmin, setIsPuzzleAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const additionalLinks = isSuperAdmin
    ? [
        {
          icon: <FontAwesomeIcon icon={faUserLock} />,
          iconSelected: icons.organizationsSelected,
          name: "Puzzle Admins",
          path: "/puzzleadmins",
          admin: true,
          role: "admin",
        },
      ]
    : [];

  const links = [
    // Home
    {
      icon: <FontAwesomeIcon icon={faHome} />,
      iconSelected: icons.homeColor,
      name: "Home",
      path: "/",
    },

    // Chat
    {
      icon: <FontAwesomeIcon icon={faMessage} />,
      iconSelected: icons.chatColor,
      name: "Chat",
      path: "/chat",
      role: "user",
    },

    // Team/Contacts
    {
      icon: <FontAwesomeIcon icon={faUsers} />,
      iconSelected: icons.directorySelected,
      name: "Team",
      path: "/contacts",
      role: "user",
    },

    // Profile
    {
      icon: <FontAwesomeIcon icon={faUserAlt} />,
      iconSelected: icons.profileSelected,
      name: "Profile",
      path: "/profile",
      role: "user",
    },

    // PuzzleAdmin Users List
    {
      icon: <FontAwesomeIcon icon={faUsers} />,
      iconSelected: icons.usersSelected,
      name: "Users",
      path: "/users",
      admin: true,
      role: "admin",
    },

    // PuzzleAdmin Organizations List
    {
      icon: <FontAwesomeIcon icon={faBuildingUser} />,
      iconSelected: icons.organizationsSelected,
      name: "Organizations",
      path: "/organizations",
      admin: true,
      role: "admin",
    },
    ...additionalLinks,
  ];

  useEffect(() => {
    const link = links.filter(
      (data) => data.path === path && data.role === role
    )[0];

    if (!link && role === "admin") {
      if (isPuzzleAdmin) return setPath("/users");
      return setPath("/surveys");
    }

    if (!link) {
      setPath("/");
    }
  }, [role]);

  useEffect(() => {
    if (auth.user.groups) {
      const groupIds = auth.user.groups.map((group) => group._id);
      if (groupIds.includes("65406e8bf9134425b97d7a5c")) {
        setIsPuzzleAdmin(true);
      }
    }
    setIsSuperAdmin(auth.user.superAdmin);
  }, [auth]);

  const link = (link, index) => {
    const className = conditional("link", style, {
      selected: path === link.path,
    });

    return (
      <div
        key={index}
        className={`flex flex-col gap-2 items-center w-full cursor-pointer hover:text-lime-600 ${
          path === link.path ? "text-lime-600" : "text-slate-600"
        }`}
        onClick={() => setPath(link.path)}
      >
        {link.icon}
        <div className="text-[8px] font-bold">{link.name}</div>
      </div>
    );
  };

  const profile = () => {
    if (!auth || !auth.user) return {};
    return auth.user;
  };

  return (
    <div className="w-[77px]">
      <div className={style.content}>
        <div className="flex flex-col gap-5 items-center mt-5">
          {links
            .filter((link) =>
              link.path === "/" && role === "admin" ? false : true
            )
            .filter((link) => (link.role ? link.role === role : true))
            .filter((link) =>
              link.admin
                ? profile().admin === true
                : link.groupAdmin
                ? groupAdmin
                : true
            )
            .map(link)}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
