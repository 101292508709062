import styles from './style.module.css';

const Loader = ({
  text = "Loading...",
  align = "center"
}) => (
  <div className={align === 'center' ? styles.loaderContainer : styles.loaderContainerRight}>
    <div className={styles.spinner}></div>
    <p className="text-sm">{text}</p>
  </div>
);
export default Loader;