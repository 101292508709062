import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";

import NavigationView from "../ContentView";
import conditional from "../../utils/conditional";
import icons from "../../resources/icons";
import ConfirmDialog from "../ConfirmDialog";
import { useAppState } from "../../contexts/AppState";
import date from "../../utils/date";
import surveysV2 from "../../api/surveysV2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarWeek,
  faClock,
  faTrashAlt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

dayjs.extend(relativeTime);

const SurveyView = (props) => {
  const [current, setCurrent] = useState(null);
  const [question, setQuestion] = useState(null);
  const [timeData, setTimeData] = useState([]);
  const [timeFields, setTimeFields] = useState([]);
  const [dialog, setDialog] = useState(null);
  const [extendDate, setExtendDate] = useState(null);

  const chartRef = useRef(null);

  // the chart data we process from survey's responseMap
  const [chartData, setChartData] = useState();

  // representation of objects above segregated monthly
  const [monthlySegregatedData, setMonthlySegregatedData] = useState();

  // the chart data that represents each month's average score and total respondents
  const [monthLevelChartData, setMonthLevelChartData] = useState();

  // the chart data that's being shown
  const [shownChartData, setShownChartData] = useState();

  const [chartLevel, setChartLevel] = useState(1);

  const [isFetching, setIsFetching] = useState(true);

  const [surveyItem, setSurveyItem] = useState();
  const [itemTimestamps, setItemTimestamps] = useState(null);

  const [scroll, setScroll] = useState(null);
  const [showIndicator, setShowIndicator] = useState(false);

  const { group, setGroup } = useAppState();

  const backIdentifier = useRef(null);
  const previous = useRef(null);

  const fetch = async () => {
    setIsFetching(true);
    const result = await surveysV2.getSingleSurveyAnalytics(surveyItem._id);
    setIsFetching(false);
    setChartData(result);
  };

  useEffect(() => {
    // fetch();
  }, []);

  useEffect(() => {
    setSurveyItem(props.data);
  }, [props.data]);

  const wait = (time) =>
    new Promise((resolve) =>
      setTimeout(() => {
        resolve();
      }, time)
    );

  const back = async () => {
    props.onBack();

    const chat = current;
    const identifier = Math.random().toString(36);
    backIdentifier.current = identifier;

    await wait(500);

    if (current !== chat || backIdentifier.current !== identifier) return;
    setCurrent(null);
    previous.current = null;

    setQuestion(null);
    setTimeData([]);
    setTimeFields([]);
  };

  const remove = async () => {
    setDialog({
      title: `Delete This Question?`,
      description: `If you delete, users will no longer get prompted to answer this question.`,
      main: {
        title: "Delete",
        action: async () => {
          const surveyItemId = surveyItem._id;
          let groupId = props.group;

          const result = await surveysV2.deleteQuestion(groupId, surveyItemId);

          if (!result) {
            console.log("Error removing survey");
            return;
          }

          const currentChat = current;
          const identifier = Math.random().toString(36);
          backIdentifier.current = identifier;

          await wait(500);
          props.onFinish && props.onFinish();
          props.onArchive && props.onArchive();
          setDialog(null);

          if (current !== currentChat || backIdentifier.current !== identifier)
            return;
          setCurrent(null);
          previous.current = null;
        },
      },
      secondary: {
        title: "Cancel",
        action: async () => {
          setDialog(null);
        },
      },
    });
  };

  const actions = [
    {
      icon: icons.archive,
      perform: remove,
    },
  ];

  const updateEndDate = async () => {
    const surveyItemId = surveyItem._id;
    let groupId = props.group;

    await surveysV2.updateEndDate(groupId, surveyItemId, extendDate);
    back();
  };

  const lines = (message) =>
    message.split("\n").map((line, index) => (
      <div
        className={style.itemPreview}
        style={{ marginTop: index ? "5px" : "0px" }}
      >
        {line}
      </div>
    ));

  const item = (item, index) => (
    <div className={conditional("item", style, {})}>
      <div className={style.itemContent}>
        <div
          className={style.itemPhoto}
          style={{
            backgroundImage: item.user
              ? `url(${item.user.thumbnail})`
              : "url(https://cdn2.hubspot.net/hub/6444014/hubfs/PuzzleHR_October2019/images/Puzzle_favicon-150x150.png?width=108&height=108)",
            backgroundSize: !item.user ? "70% 70%" : "cover",
          }}
        />
        <div className={style.itemInfo}>
          <div className={style.itemNameContent}>
            <div className={style.itemName}>
              {item.user
                ? item.user.firstName + " " + item.user.lastName
                : "Harley"}
            </div>
            <div className={style.itemDate}>{date.format(item.date)}</div>
          </div>
          {lines(item.message)}
        </div>
      </div>
    </div>
  );

  const percentage = (item) => {
    if (!props.data || !props.data.results || !props.data.results.length)
      return "0%";

    const total = props.data.results.reduce(
      (sum, result) => sum + result.count,
      0
    );
    if (!total) return "0%";

    const percent = (item.count / total) * 100;
    return `${parseInt(percent)}%`;
  };

  const max = () => {
    if (!props.data || !props.data.results || !props.data.results.length)
      return null;

    let max = 0;
    let result = null;

    for (const data of props.data.results) {
      if (data.count > max) {
        max = data.count;
        result = data;
      }
    }

    return result ? result.index : null;
  };

  const timeSeries = () => {
    if (!question) return [];

    const results = question.results;
    console.log("rest", results);
  };

  const card = (item) => {
    const surveyCardClass = conditional("surveyCard", style, {
      highlighted: max() === item.index,
    });

    return (
      <div className={surveyCardClass}>
        <div className={style.surveyHeader}>
          <div className={style.featuredInfo}>
            <div className={style.featuredTitle}>{item.content}</div>
            <div className={style.featuredSubtitle}>
              {item.count} response{item.count !== 1 ? "s" : ""}
            </div>
          </div>
          <div className={style.featuredNumber}>{percentage(item)}</div>
        </div>
      </div>
    );
  };

  const resultItem = (item, index) => (
    <div className={conditional("resultItem", style, { selected: false })}>
      <div className={style.resultItemContent}>
        <div
          className={style.resultItemPhoto}
          style={{ backgroundImage: "" }}
        />
        <div className={style.resultItemInfo}>
          <div className={style.resultItemNameContent}>
            <div className={style.resultItemName}>{item.name}</div>
            <div className={style.resultItemDate}>{item.count}x</div>
          </div>
          {/* <div className={style.resultItemPreview}>{item.secondaryInfo.description || 'Serving customers'}</div> */}
        </div>
      </div>
    </div>
  );

  const surveyViewClass = conditional("SurveyView", style, {
    visible: props.data !== null,
  });
  const surveysClass = conditional("surveys", style, { visible: true });
  const indicatorClass = conditional("indicator", style, {
    visible: showIndicator,
  });

  useEffect(() => {
    if (!surveyItem || !surveyItem.responses) return;
    setItemTimestamps(Object.keys(surveyItem.responses));

    const keys = Object.keys(surveyItem.responses);
    setChartData(
      keys.map((period, index) => {
        return {
          label: period,
          averageScore:
            Object.values(surveyItem.responses)[index].reduce(
              (sum, item) => sum + item.value,
              0
            ) / Object.values(surveyItem.responses)[index].length,
          respondentsCount: Object.values(surveyItem.responses)[index].length,
        };
      })
    );
  }, [surveyItem]);

  useEffect(() => {
    if (!chartData) return;
    const months = [
      ...new Set(chartData.map((chartData) => chartData.label.split("/")[0])),
    ];

    //   charts data per month
    const monthlySegregatedData = months.map((month) => {
      return {
        month: month,
        labels: chartData
          .filter((chartData) => chartData.label.split("/")[0] == month)
          .map((i) => i.label),
        averageScore: chartData
          .filter((chartData) => chartData.label.split("/")[0] == month)
          .map((i) => i.averageScore),
        respondentsCount: chartData
          .filter((chartData) => chartData.label.split("/")[0] == month)
          .map((i) => i.respondentsCount),
      };
    });

    setMonthlySegregatedData(monthlySegregatedData);

    // from the computation above, get the chartsdata representation
    const monthLevelChartData = {
      labels: monthlySegregatedData.map((i) =>
        new Date(i.month).toLocaleString("en-US", { month: "long" })
      ),
      averageScore: monthlySegregatedData.map(
        (i) =>
          i.averageScore.reduce((sum, item) => sum + item) /
          i.averageScore.length
      ),
      respondentsCount: monthlySegregatedData.map((i) =>
        i.averageScore.reduce((sum, item) => sum + item)
      ),
    };

    setMonthLevelChartData(monthLevelChartData);

    //   default
    setShownChartData(monthLevelChartData);
  }, [chartData]);

  useEffect(() => {
    console.log(monthlySegregatedData);
  }, [monthlySegregatedData]);

  const yAxisIcons = {
    1: "🟢",
    2: "🟠",
    3: "🟡",
    4: "🟢",
    5: "🔵",
  };

  if (!surveyItem) return <div className={surveyViewClass} />;

  return (
    <div className={surveyViewClass}>
      <NavigationView
        secondary
        title={surveyItem.label}
        bottom
        scroll={scroll}
        onBack={back}
        actions={actions}
      >
        <div className={style.info}>
          <div className={style.iconWrapper}>
            <FontAwesomeIcon className={style.greyIcon} icon={faClock} />{" "}
            <div className={style.infoText}>
              {"Repeats " + surveyItem.timing}
            </div>
          </div>
          <div onClick={remove} className={style.deleteQuestion}>
            <FontAwesomeIcon icon={faTrashAlt} /> Delete Question
          </div>
        </div>
        {(surveyItem.startDate !== "null" || surveyItem.endDate !== "null") && (
          <>
            <div className={style.info}>
              <div className={style.iconWrapper}>
                <FontAwesomeIcon className={style.greyIcon} icon={faCalendar} />{" "}
                <div className={style.infoText}>
                  {surveyItem.startDate} — {surveyItem.endDate}
                </div>
              </div>
            </div>
            <div className={style.info}>
              <div className={style.iconWrapper}>
                {new Date(surveyItem.nextRun) > new Date(surveyItem.endDate) ? (
                  <>
                    <FontAwesomeIcon
                      className={style.greenIcon}
                      icon={faCalendarCheck}
                    />{" "}
                    <div className={style.greenText}>Run complete</div>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      className={style.greyIcon}
                      icon={faCalendarWeek}
                    />{" "}
                    <div className={style.infoText}>
                      Next Run: {surveyItem.nextRun}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {(surveyItem.startDate !== "null" || surveyItem.endDate !== "null") && (
          <>
            <div className={style.info}>
              {new Date(surveyItem.nextRun) < new Date(surveyItem.endDate) && (
                <>
                  <div className={style.extendDateWrapper}>
                    <FontAwesomeIcon
                      className={style.greyIcon}
                      icon={faCalendarPlus}
                    />
                    <div className={style.infoText}>Extend until: </div>
                  </div>
                  <input
                    onChange={(e) =>
                      setExtendDate(dayjs(e.target.value).format("MM/DD/YYYY"))
                    }
                    min={
                      new Date(
                        new Date(surveyItem.endDate).setDate(
                          new Date(surveyItem.endDate).getDate() + 2
                        )
                      )
                        .toISOString()
                        .split("T")[0]
                    }
                    className={style.extendDateInput}
                    type="date"
                  />
                  {extendDate !== null && (
                    <div
                      onClick={updateEndDate}
                      className={style.saveExtendDateButton}
                    >
                      Save
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

        <div className="p-5 -mt-[40px]">
          {surveyItem.timing !== "once" && shownChartData && (
            <>
              <div className="h-[30px] -mb-[20px]">
                {chartLevel === 2 && (
                  <div
                    onClick={() => {
                      setShownChartData(monthLevelChartData);
                      setChartLevel(1);
                    }}
                    className="flex text-sm gap-2 cursor-pointer hover:text-lime-600 items-center"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Monthly View
                  </div>
                )}
              </div>

              <div>
                <Line
                  data={{
                    labels:
                      shownChartData.labels && shownChartData.labels.length > 5
                        ? shownChartData.labels.slice(-5)
                        : shownChartData.labels,
                    datasets: [
                      {
                        label: "Average Score",
                        data:
                          shownChartData.averageScore &&
                          shownChartData.averageScore.length > 5
                            ? shownChartData.averageScore.slice(-5)
                            : shownChartData.averageScore,
                        backgroundColor: "#ecfccb4d",
                        borderColor: "#65a30d",
                        borderWidth: 1,
                        pointRadius: 7, // Makes the plot points larger
                        pointHoverRadius: 8, // Increases the point size on hover
                        pointBorderWidth: 2,
                        tooltip: {
                          callbacks: {
                            label: function (tooltipItem) {
                              const index = tooltipItem.dataIndex;
                              const averageScore = tooltipItem.raw;
                              const respondents =
                                shownChartData.respondentsCount
                                  ? shownChartData.respondentsCount[index]
                                  : "N/A";
                              return [
                                `Average Score: ${averageScore.toFixed(2)}`,
                                `No. of Respondents: ${respondents}`,
                              ];
                            },
                          },
                        },
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                        labels: {
                          font: {
                            size: 10,
                          },
                          padding: 20,
                          boxWidth: 10,
                        },
                      },
                      datalabels: {
                        display: false,
                        anchor: "end", // positions label at the end of the bar
                        align: "end", // aligns the label to the top of the bar
                        offset: -20,
                        formatter: (value) => value.toFixed(2), // formats label to show the value
                        color: "black", // label color
                        font: {
                          weight: "normal",
                          size: 10,
                        },
                      },
                    },
                    layout: {
                      padding: {
                        top: 0,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true, // Ensures the scale starts from 0
                        min: 0, // Explicitly sets the minimum value
                        max: 5, // Explicitly sets the maximum value
                        ticks: {
                          stepSize: 1, // Makes the y-axis increment by 1
                          callback: function (value) {
                            if (value === 0) return "N/A"; // Label 0 as "N/A"
                            return `${yAxisIcons[value] || ""} ${value}`;
                          },
                        },
                      },
                    },
                    onClick: (event) => {
                      const elements = event.chart.getElementsAtEventForMode(
                        event,
                        "nearest",
                        { intersect: true },
                        true
                      );

                      if (elements.length > 0) {
                        const { datasetIndex, index } = elements[0];
                        if (chartLevel === 1) {
                          setShownChartData(monthlySegregatedData[index]);
                          setChartLevel(2);
                        }
                      }
                    },
                  }}
                />
              </div>
            </>
          )}
        </div>

        <div className={style.items}>
          <div className={style.section}>
            <div className={style.sectionContent}>
              <table className={style.table}>
                <thead>
                  <td>Period</td>
                  <td># of Respondents</td>
                  <td>Period Score</td>
                </thead>
                {itemTimestamps && (
                  <tbody>
                    {itemTimestamps &&
                      itemTimestamps.map((timestamp, index) => (
                        <tr>
                          <td>{timestamp}</td>
                          <td>
                            {surveyItem.responses[itemTimestamps[index]] &&
                              surveyItem.responses[itemTimestamps[index]]
                                .length}
                          </td>
                          <td>
                            {surveyItem &&
                              surveyItem.responses[itemTimestamps[index]] &&
                              surveyItem.responses[itemTimestamps[index]]
                                .length &&
                              (
                                surveyItem.responses[itemTimestamps[index]]
                                  .map((i) => Number(i.value))
                                  .reduce((a, c) => a + c, 0) /
                                surveyItem.responses[itemTimestamps[index]]
                                  .length
                              ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
          <div style={{ height: "16px" }} />
        </div>
      </NavigationView>
      <ConfirmDialog data={dialog} />
    </div>
  );
};

export default SurveyView;
