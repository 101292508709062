import React, { useRef, useState, useEffect } from "react";
import style from "./style.module.css";

import NavigationView from "../ContentView";
import icons from "../../resources/icons";
import conditional from "../../utils/conditional";
import { useEffectGroup } from "../../contexts/AppState";
import users from "../../api/users";
import { useAppState } from "../../contexts/AppState";
import ListHeader from "../ListHeader";
import UserView from "../UserView";
import NewUserView from "../NewUserView";
import Loader from "../v2/LoaderInline/LoaderInline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faInfoCircle,
  faSearch,
  faTimes,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import PuzzleAdminView from "../PuzzleAdminView";

const PuzzleAdmins = (props) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [updates, setUpdates] = useState(null);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // New state for current page
  const itemsPerPage = 20; // Adjust this to set items per page

  const groupIdentifier = useRef();
  const { group } = useAppState();

  const fetchPaginatedUsers = async (group) => {
    setIsLoading(true);
    const results = await users.list(
      currentPage,
      search,
      "65406e8bf9134425b97d7a5c"
    );

    if (!results) {
      console.log("error fetching users");
      setIsLoading(false);
      return;
    }

    setItems(results.data);
    setTotalPages(results.pagination.totalPages);
    setTotalUsers(results.pagination.totalUsers);
    setCurrentPage(results.pagination.currentPage);
    groupIdentifier.current = group;
    setIsLoading(false);
  };

  const add = async () => {
    setUser({});
  };

  const updateChat = (chat) => {
    // Logic to update chat
  };

  const onSave = () => {
    setUser(null);
    fetchPaginatedUsers(group);
  };

  const select = (index) => {
    setSelected(index);
  };

  // Todo: finish clear search function
  const clearSearch = async () => {
    setSearch("");
    if (currentPage === 1) {
      await fetchPaginatedUsers();
      return;
    }
    setCurrentPage(1);
  };

  const addChat = (chat) => {
    setItems((items) =>
      items.map((item) => (item._id === "new" ? chat : item))
    );
  };

  const handlePageChange = async (page) => {
    setSelected(null);
    setCurrentPage(page);
  };

  const handleSearch = async () => {
    if (currentPage === 1) {
      fetchPaginatedUsers();
      return;
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    const execute = async () => {
      setIsLoading(true);
      await fetchPaginatedUsers();
      setIsLoading(false);
    };
    execute();
  }, [currentPage]);

  useEffectGroup(fetchPaginatedUsers);

  const actions = [
    {
      name: "Sync",
      perform: () => fetchPaginatedUsers(),
    },
    {
      icon: icons.add,
      perform: () => add(),
    },
  ];

  const item = (item, index) => (
    <div
      key={index}
      className={conditional("item", style, { selected: selected === index })}
      onClick={() => select(index)}
    >
      <div className={style.itemContent}>
        <div className={style.itemInfo}>
          <div className={style.itemNameContent}>
            <div className="flex flex-col gap-1">
              <div className="flex gap-1 text-sm font-bold items-center">
                <span className="text-sm">
                  {(currentPage - 1) * 20 + index + 1}.
                </span>{" "}
                <FontAwesomeIcon
                  className="text-lime-600 text-xs"
                  icon={faUserLock}
                />
                {item.firstName} {item.lastName}{" "}
              </div>
              <span className={style.emailMuted}>({item.email})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const visiblePages = 3; // Number of pages to always show at start and end

  const getPaginationRange = () => {
    const pages = [];
    const halfVisible = Math.floor(visiblePages / 2);

    let startPage = Math.max(1, currentPage - halfVisible);
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    // Adjust if we're too close to the end
    if (endPage === totalPages) {
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    // Always add '...' before the middle section (except when it's near the beginning)
    if (startPage > 2) {
      pages.push(1, "...");
    } else if (startPage === 2) {
      pages.push(1);
    }

    // Add the main range of numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Always add '...' after the middle section (except when it's near the end)
    if (endPage < totalPages - 1) {
      pages.push("...", totalPages);
    } else if (endPage === totalPages - 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <div className={style.UserList}>
      <NavigationView primary title="PuzzleAdmins" actions={actions}>
        <p className="pl-5 mb-4 text-xs flex gap-2">
          <FontAwesomeIcon
            className="text-sm text-slate-500"
            icon={faInfoCircle}
          />
          Assign Puzzlers to manage organizations.
        </p>
        <div className="min-h-[100vh] flex flex-col">
          <div className="flex flex-col gap-3 mx-4 mb-3">
            <div className="flex">
              <input
                value={search}
                className="flex-grow border border-gray-300  outline-none focus:border-lime-600 p-2 text-sm"
                placeholder={`Start typing...`}
                disabled={isLoading}
                onChange={(e) => setSearch(e.target.value)}
              />

              <button
                disabled={isLoading}
                onClick={handleSearch}
                className="px-2 flex gap-2 items-center text-xs bg-lime-600 py-0 text-white"
              >
                <FontAwesomeIcon icon={faSearch} />
                Search
              </button>
            </div>
            <p className="text-xs text-slate-700">
              Showing {(currentPage - 1) * 20 + 1} -{" "}
              {Math.min(currentPage * 20, totalUsers)} out of {totalUsers}{" "}
              Puzzlers
            </p>
          </div>

          {isLoading ? (
            <div className="px-5">
              <Loader text="" />
            </div>
          ) : (
            <div className={style.items}>{items.map(item)}</div>
          )}

          <div className="w-full bg-slate-100 sticky mt-auto bottom-0">
            <div className="flex p-3">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="text-xs px-2 hover:bg-[#7ac142] hover:text-white"
              >
                Prev
              </button>

              <div className="ml-auto">
                {getPaginationRange().map((page, index) => (
                  <button
                    key={index}
                    onClick={() => page !== "..." && handlePageChange(page)}
                    className={`text-xs h-[30px] w-[30px] ${
                      currentPage === page ? "bg-[#7ac142] text-white" : ""
                    }`}
                    disabled={page === "..."} // Disable ellipsis button
                  >
                    {page}
                  </button>
                ))}
              </div>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-xs px-2 hover:bg-[#7ac142] hover:text-white ml-auto"
              >
                Next
              </button>
            </div>
            {isLoading && (
              <div
                id="disabled-wrapper"
                className="absolute w-full h-full bg-black/10 z-50 bottom-0"
              ></div>
            )}
          </div>
        </div>
      </NavigationView>

      {selected !== null && (
        <PuzzleAdminView
          data={items[selected]}
          updates={updates}
          add={addChat}
          update={updateChat}
          onBack={() => setSelected(null)}
          onArchive={() => {
            setSelected(null);
            fetchPaginatedUsers(group);
          }}
          onUpdate={() => {
            fetchPaginatedUsers(group);
          }}
        />
      )}

      <NewUserView data={user} onBack={() => setUser(null)} onSave={onSave} />
    </div>
  );
};

export default PuzzleAdmins;
