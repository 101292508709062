import React, { useState, useRef, useEffect } from "react";
import style from "./style.module.css";
import icons, { awardIcon } from "../../resources/icons";

import NavigationView from "../ContentView";
import SegmentTabs from "../SegmentTabs";
import { useAppState, useEffectGroup } from "../../contexts/AppState";
import { useTabSwitcher } from "../../utils/tabSwitcher";
import conditional from "../../utils/conditional";
import users from "../../api/users";
import groups from "../../api/groups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Loader from "../v2/LoaderInline/LoaderInline";

const actions = [];

const NewUserRoleView = (props) => {
  const { auth, group } = useAppState();

  const [current, setCurrent] = useState(null);

  const [dialog, setDialog] = useState(null);

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [title, setTitle] = useState("");
  const [
    permissionTabs,
    selectedPermission,
    setSelectedPermission,
    permissionViews,
  ] = useTabSwitcher("page", style, ["Standard", "Admin"]);

  const [isLoading, setIsLoading] = useState(false);

  const [appUsers, setAppUsers] = useState([]);

  const backIdentifier = useRef(null);
  const previous = useRef(null);
  const groupRef = useRef(null);

  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // New state for current page
  const itemsPerPage = 20; // Adjust this to set items per page

  const fetchPaginatedUsers = async (group) => {
    setIsLoading(true);
    const results = await users.list(1, search);

    if (!results) {
      console.log("error fetching users");
      setIsLoading(false);
      return;
    }

    setAppUsers(results.data);
    setTotalPages(results.pagination.totalPages);
    setTotalUsers(results.pagination.totalUsers);
    setCurrentPage(results.pagination.currentPage);
    setIsLoading(false);
  };

  function handleSearch() {
    if (search.length <= 3) {
      return alert(
        "Type in more than 3 characters - so we can narrow down the search."
      );
    }
    fetchPaginatedUsers();
  }

  useEffect(() => {
    if (!props.data) return;
    setCurrent(props.data);

    const identifier = Math.random().toString(36);
    backIdentifier.current = identifier;

    fetchPaginatedUsers(group);
  }, [props.data]);

  const wait = (time) =>
    new Promise((resolve) =>
      setTimeout(() => {
        resolve();
      }, time)
    );

  const back = async () => {
    props.onBack();

    const profile = current;
    const identifier = Math.random().toString(36);
    backIdentifier.current = identifier;

    await wait(500);

    if (
      (current && profile && current._id !== profile._id) ||
      backIdentifier.current !== identifier
    )
      return;
    setCurrent(null);
    previous.current = null;

    setSearch("");
    setSelected(null);
    setSuggestions([]);
    setSelectedPermission(0);
    setTitle("");
  };

  const save = async () => {
    // alert(JSON.stringify(current));
    // if (![firstName, lastName].every(field => field.length > 0)) {
    //     alert('You must enter a valid name for this user');
    //     return;
    // }

    // if (email.length < 5 || !email.includes('@') || !email.includes('.') || email.trim().includes(' ')) {
    //     alert('You must enter a valid email for this user');
    //     return;
    // }

    // if (phone.length < 11) {
    //     alert('You must enter a valid mobile phone for this user');
    //     return;
    // }

    if (!selected) {
      alert("You must select a user first.");
      return;
    }

    if (!title.length) {
      alert("You must enter a title first.");
      return;
    }

    const result = await groups.put(current.group, selected._id, {
      title,
      admin: selectedPermission === 1,
    });

    props.onSave();

    const profile = current;
    const identifier = Math.random().toString(36);
    backIdentifier.current = identifier;

    await wait(500);

    if (
      (current && profile && current._id !== profile._id) ||
      backIdentifier.current !== identifier
    )
      return;
    setCurrent(null);
    previous.current = null;

    setSearch("");
    setSelected(null);
    setSuggestions([]);
    setSelectedPermission(0);
    setTitle("");
  };

  const finish = () => {
    setDialog(null);
  };

  const suggestion = (data) => (
    <div
      className={style.suggestion}
      onClick={() => {
        setSelected(data);
      }}
    >
      <div className={style.suggestionName}>
        {data.firstName} {data.lastName}
      </div>
      <div className={style.suggestionPreview}>{data.email}</div>
    </div>
  );

  const item = (item, index) => (
    <div className={conditional("item", style, {})} onClick={() => {}}>
      <div className={style.itemContent}>
        <div className={style.itemInfo}>
          <div
            className={style.itemPhoto}
            style={{ backgroundImage: `url(${item.thumbnail})` }}
          />
          <div className={style.itemNameContent}>
            <div className={style.itemName}>
              {item.firstName + " " + item.lastName}
            </div>
            <div className={style.itemPreview}>{item.email}</div>
          </div>
          <div
            className={style.delete}
            style={{ backgroundImage: icons.remove }}
            onClick={() => {
              setSelected(null);
              setSearch("");
            }}
          ></div>
        </div>
      </div>
    </div>
  );

  const newUserRoleViewClass = conditional("NewUserRoleView", style, {
    visible: props.data,
  });

  return (
    <div className={newUserRoleViewClass}>
      <div className={style.container}>
        <NavigationView
          title="Add User"
          modal
          onClose={!props.root ? back : null}
          hideHeader
          persistTitle
          actions={actions}
        >
          <p className="pl-5 mb-4 text-xs flex gap-2">
            <FontAwesomeIcon
              className="text-sm text-slate-500"
              icon={faInfoCircle}
            />
            PuzzleTip - you can now search by user's first name, last name, and
            email!
          </p>
          <div className={style.profile}>
            {selected ? (
              <div className={style.answerInput}>{item(selected)}</div>
            ) : (
              <div className={style.answerInput}>
                <div className="flex">
                  <input
                    value={search}
                    className="flex-grow border border-gray-300  outline-none focus:border-lime-600 p-2 text-sm"
                    placeholder={`Start typing...`}
                    disabled={isLoading}
                    onChange={(e) => setSearch(e.target.value)}
                  />

                  <button
                    disabled={isLoading}
                    onClick={handleSearch}
                    className="px-2 flex gap-2 items-center text-xs bg-lime-600 py-0 text-white"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                    Search
                  </button>
                </div>
                <div className="mt-5">{isLoading && <Loader text="" />}</div>

                {appUsers.length > 0 && !isLoading ? (
                  <div
                    className={style.suggestions}
                    style={{
                      display: appUsers.length ? "block" : "none",
                    }}
                  >
                    {appUsers.map(suggestion)}
                  </div>
                ) : isLoading ? (
                  <></>
                ) : (
                  <p className="text-xs">
                    Sorry we found no matching users. Maybe try a better query?
                  </p>
                )}
              </div>
            )}
          </div>
          <div className={style.section}>
            <div className={style.sectionTitle}>Company info</div>
            <div className={style.sectionContent}>
              <input
                className={style.answer}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
              />
            </div>
          </div>
          <div className={style.section}>
            <div className={style.sectionTitle}>Access</div>
            <div
              className={style.sectionContent}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <SegmentTabs
                selectedTab={selectedPermission}
                tabs={permissionTabs}
                onSelect={(index) => {
                  setSelectedPermission(index);
                }}
              />
              <div className={style.surveyActions}>
                <div
                  className={style.surveyAction + " " + style.secondary}
                  onClick={back}
                >
                  Cancel
                </div>
                <div className={style.surveyAction} onClick={save}>
                  Save
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "20px" }} />
        </NavigationView>
      </div>
    </div>
  );
};

export default NewUserRoleView;
