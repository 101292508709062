import { component } from "./";
const request = component("/surveysV2");

const createQuestion = async ({
  label,
  timing,
  timestamp,
  groupId,
  category,
  startDate,
  endDate,
}) => {
  console.log("reached");

  const result = await request("/createQuestion", {
    label,
    timing,
    timestamp,
    groupId,
    category,
    startDate,
    endDate,
  });
  return result;
};

const getQuestions = async (groupId) => {
  try {
    const results = await request("/getQuestions", { groupId });
    return results;
  } catch (e) {
    return null;
  }
};

const getQuestionsForUser = async (groupId) => {
  try {
    const results = await request("/getQuestionsForUser", { groupId });
    return results;
  } catch (e) {
    return null;
  }
};

const answerQuestion = async (groupId, questionId, value) => {
  try {
    const results = await request("/answerQuestion", {
      groupId,
      questionId,
      value,
    });
    return results;
  } catch (e) {
    return null;
  }
};

const deleteQuestion = async (groupId, surveyItemId) => {
  try {
    const results = await request("/deleteQuestion", { groupId, surveyItemId });
    return results;
  } catch (e) {
    return null;
  }
};

const getSurveyAnalytics = async (groupId) => {
  try {
    const results = await request("/getSurveyAnalytics", { groupId });
    return results;
  } catch (e) {
    return null;
  }
};

const updateEndDate = async (groupId, surveyItemId, newEndDate) => {
  try {
    const questionId = surveyItemId;
    const results = await request("/updateEndDate", {
      groupId,
      questionId,
      newEndDate,
    });
    return results;
  } catch (e) {
    return null;
  }
};

const getPPQuestions = async () => {
  try {
    const ppQuestions = await request("/getPPQuestions", {});
    return ppQuestions;
  } catch (e) {
    return null;
  }
};

const importPP = async ({
  timing,
  timestamp,
  groupId,
  startDate,
  endDate,
  ppQuestionIndices,
}) => {
  const result = await request("/importPP", {
    ppQuestionIndices,
    timing,
    timestamp,
    groupId,
    startDate,
    endDate,
  });
  return result;
};

const getSingleSurveyAnalytics = async ({ groupId, questionId }) => {
  const result = await request("/getSingleSurveyAnalytics", {
    groupId,
    questionId,
  });
  return result;
};

export default {
  getSingleSurveyAnalytics,
  importPP,
  getPPQuestions,
  createQuestion,
  getQuestions,
  getQuestionsForUser,
  answerQuestion,
  deleteQuestion,
  getSurveyAnalytics,
  updateEndDate,
};
